import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from "react-share"
import {
	faFacebookF,
	faLinkedinIn,
	faTwitter,
} from "@fortawesome/free-brands-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../components/layout/layout"
import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const RenderSocialLinks = ({ newsItem, url }) => (
	<dl className="grid grid-cols-1 gap-y-8 justify-center md:justify-start">
		<div className="ml-10">
			<dt className="text-sm font-bold text-gray-500">Published</dt>
			<dd className="mt-1 text-sm text-gray-900">
				{new Intl.DateTimeFormat("en-AU", {
					timeZone: "Australia/NSW",
					year: "numeric",
					month: "long",
					day: "2-digit",
				}).format(new Date(newsItem.authorizedOn))}
			</dd>
		</div>
		<div>
			<dt className="text-sm font-bold text-gray-500 ml-10">Social Sharing</dt>
			<dd className="mt-1 text-xl text-gray-900 pb-1 ml-10">
				<LinkedinShareButton
					title={newsItem.name}
					summary={newsItem.excerpt}
					url={url}
				>
					<span className="text-primary">
						<FontAwesomeIcon icon={faLinkedinIn} />
					</span>
				</LinkedinShareButton>
				<br />
				<TwitterShareButton title={newsItem.name} url={url}>
					<span className="text-primary">
						<FontAwesomeIcon icon={faTwitter} />
					</span>
				</TwitterShareButton>
				<br />
				<FacebookShareButton
					title={newsItem.name}
					url={url}
					quote={newsItem.excerpt}
				>
					<span className="text-primary">
						<FontAwesomeIcon icon={faFacebookF} />
					</span>
				</FacebookShareButton>
			</dd>
		</div>
	</dl>
)

const RenderItem = ({ newsItem }) => (
	<>
		<h1 className="text-4xl pb-4 mb-4 text-neutral font-bold">
			{newsItem.name}
		</h1>
		<div
			className="crmContent"
			dangerouslySetInnerHTML={{ __html: newsItem.content }}
		></div>
	</>
)

const NewsItem = props => {
	const {
		data: { newsItem },
		location,
	} = props
	return (
		<Layout>
			<Seo
				title={`IPWEA News - ${newsItem.name}`}
				description={newsItem?.name}
				meta={[
					{
						property: "og:image",
						content: `${
							typeof window !== "undefined" ? window.location.origin : ""
						}${
							newsItem?.thumbnailImages?.childImageSharp?.gatsbyImageData
								?.images?.fallback?.src
						}`,
					},
				]}
			/>
			<div className="grid grid-cols-6 gap-4 mx-1 my-2 md:mx-4 md:my-10">
				<aside className="col-span-full sm:col-span-1">
					<RenderSocialLinks newsItem={newsItem} url={location.href} />
				</aside>
				<article className="col-span-full sm:col-span-5">
					<RenderItem newsItem={newsItem} />
				</article>
				{/* <div className="col-span-full sm:col-span-1 text-neutral mr-10">
					<RelatedNewsList list={[newsItem]} />
					<UpcomingEvents />
				</div> */}
			</div>
		</Layout>
	)
}
export default NewsItem

export const pageQuery = graphql`
	query NewsItemQuery($itemId: String!) {
		newsItem(newsId: { eq: $itemId }) {
			id
			permalink
			name
			excerpt
			content
			authorizedOn
			newsId
			mediaType
			thumbnailImages {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	}
`
